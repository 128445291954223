<template>
<div class="musiceditor">
    <h1 v-show="!editState">添加音乐</h1>
    <h1 v-show="editState">修改音乐</h1>
    <div class="form">
        <div>
            <el-input class="info" v-model="music.title" placeholder="歌名"></el-input>
        </div>

        <el-input class="info" v-model="music.artist" placeholder="作者"></el-input>
        <el-input class="info" v-model="music.pic" placeholder="封面"></el-input>
        <el-input class="info" v-model="music.src" placeholder="链接"></el-input>
        <el-input class="info" v-model="music.time" v-if="showTime" placeholder="time"></el-input>
        <el-input class="info" type="textarea" :rows="4" v-model="music.lrc" placeholder="歌词"></el-input>
        <div>
            <el-button v-show="!editState" type="primary" @click="addmusic">添加音乐</el-button>
            <el-button v-show="editState" type="primary" @click="modifyMusic">修改音乐</el-button>
            <el-button type="primary" @click="clearInput">清空输入</el-button>
        </div>

    </div>

    <div class="uploader">
        <el-upload class="upload-demo" :headers="Headers" :action="uploadurl" :on-success="handle_success" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :file-list="fileList">
            <el-button size="small" type="primary">上传文件</el-button>
        </el-upload>
        <div :v-show="success">{{ url }}</div>
    </div>
    <div>
        <audio controls ref="audio" :autoplay="false" style="display:1none" :src="music.src">
            您的浏览器不支持 audio 标签。
        </audio>
        <el-button @click="getTime">获取时间</el-button>
    </div>

</div>
</template>

<script>
import {
    getStore
} from "@/utils/storage";
import url from "@/serviceAPI.config.js";
import axios from "axios";
export default {
    data() {
        return {
            editState: false, //是否为修改模式
            url: "",
            success: false,
            showTime: false,
            fileList: [],
            Headers: {
                Authorization: "",
                position: "music"
            },
            uploadurl: url.upload,
            music: {
                "title": '',
                "artist": '',
                "pic": '',
                "src": '',
                "lrc": '',
                time: Number
            }
        };
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        handle_success(res) {
            this.success = true;
            this.url = res.url;
            this.$message.success("文件上传成功");
        },
        clearInput() {
            this.music = {}
        },
        addmusic() {
            console.log(this.music)
            axios({
                    url: url.music,
                    method: "post",
                    data: {
                        name: this.music.title,
                        artist: this.music.artist,
                        cover: this.music.pic,
                        lrc: this.music.lrc,
                        url: this.music.src,
                        time:this.music.time
                    },
                })
                .then((Response) => {
                    //   console.log(Response)
                    if (Response.data.code == 100) {
                        this.$store.commit('modifyState')
                        alert("插入音乐成功");
                    } else {
                        alert("插入音乐失败，状态码错误");
                    }
                })
                .catch((error) => {
                    alert("插入音乐失败error");
                });
        },
        modifyMusic() {
            console.log(this.music)
            axios({
                    url: url.music,
                    method: "put",
                    data: {
                        id: this.music._id,
                        name: this.music.title,
                        artist: this.music.artist,
                        cover: this.music.pic,
                        lrc: this.music.lrc,
                        url: this.music.src,
                        time: this.music.time
                    }
                })
                .then((Response) => {
                    //   console.log(Response)
                    if (Response.data.code == 100) {
                        alert("修改音乐成功");
                    } else {
                        alert("修改音乐失败，状态码错误");
                    }
                })
                .catch((error) => {
                    console.log(error)
                    alert("修改音乐成功");
                });
        },
        getTime() {
            console.log(this.$refs.audio.duration)
            this.music.time = this.$refs.audio.duration
            this.$nextTick(() => {
                // dom元素更新后执行，因此这里能正确打印更改之后的值
                this.showTime = true

            })

        }
    },
    created() {
        this.editState = this.$store.state.musicState

        if (this.editState) {
            this.music = this.$route.query.id
            console.log(this.music)
        } else {
            this.music = {}
        }
        this.Headers.Authorization = getStore("token");
    },
};
</script>

<style scoped>
div {
    width: 60vw;
}

.musiceditor {
    padding: 30px;
}

.info {
    display: flex;
    justify-content: flex-start;
    margin: 10px auto;
    width: 50vw;
}

.uploader {
    margin: 20px;
}
</style>
